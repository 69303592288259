<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <PageHeader :title="title" />
        <v-row>
          <v-col
            cols="12"
            :md="showHelp ? 12 : 6"
            sm="3"
            class="pr-0"
            v-for="item in views"
            :key="item.id"
          >
            <v-card
              color="primary"
              dark
              @click="navigateToAnalytics(item.title)"
              height="100px"
            >
              <v-card-title class="text-h5">
                {{ item.title }}
              </v-card-title>

              <v-card-subtitle>{{ item.subtitle }} </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-3 pr-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mapActions } from "vuex";

export default {
  name: "OtrosTablerosAnalytics",
  components: { PageHeader, Ayuda },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.loadTarjetasOtrosTableros();
  },
  data() {
    return {
      title: "Otros tableros",
      otrosTablerosItems: null,
      optionCode: enums.webSiteOptions.ANALYTICS_OTROS_TABLEROS,
      views: [],
      showExpand: false,
      showHelp: false,
      showIcon: true
    };
  },
  methods: {
    ...mapActions({
      getOtrosTableros: "analytics/getOtrosTableros"
    }),
    async loadTarjetasOtrosTableros() {
      this.otrosTablerosItems = await this.getOtrosTableros(this.optionCode);
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      for (const item of this.otrosTablerosItems) {
        if (
          this.allowedActions.some(
            action => action.optionCode === item.optionCode
          )
        ) {
          this.views.push({
            id: item.optionId,
            title: item.optionTitle
          });
        }
      }
    },
    navigateToAnalytics(title) {
      this.$router.push(`/analyticsTable/${title}`);
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
